<template>
  <div class="outer_container">
    <div class="score_box" :style="{ color: themeColor, backgroundColor: themeLightColor }">
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div class="question_item" v-for="(item, itemIndex) in listData" :key="itemIndex">
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress :audioSrc="item.audio_url" :itemIndex="itemIndex"></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.content">
                <span v-if="activeTextIndex == itemIndex" @click="hideAudioText">
                  隐藏原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="audio_content" :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }">
          <div class="inner_text">{{ item.content }}</div>
        </div>
        <div v-for="(linkItem, linkIndex) in item.link" :key="linkItem.id">
          <div class="question_title">Question{{ linkIndex + 1 }}:</div>
          <div class="question_title">{{ linkItem.title }}</div>
          <div v-if="linkItem.content" class="question_title small">
            提示: ({{ linkItem.content }})
          </div>
          <div class="choose_text align_center">
            你的回答：
            <span v-if="!linkItem.used" class="noUsed">此题未开始作答</span>
            <div class="inner_progress_box" v-else>
              <audio-progress
                isRecord
                :recordId="linkItem.recordId"
                :audioSrc="linkItem.recordUrl"
                :itemIndex="linkIndex + itemIndex"
              ></audio-progress>
            </div>
            <span>
              <template v-if="linkItem.used">
                得分:
                <span :style="{ color: themeColor }">{{ linkItem.overall }}</span>
              </template>
            </span>
          </div>
          <div class="other_scene_text" v-if="linkItem.used">
            <div>关键词：</div>
            <div class="answer_key">
              <div v-for="answerItem in linkItem.answerKey" :key="answerItem">{{ answerItem }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLightColor } from '@/utils/common';
import globalConfig from '@/store/global_config';
import AudioProgress from '@/components/audioProgress';
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      activeTextIndex: -1,
      listData: [],
      sumScore: '',
      score: '',
      title: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },

  methods: {
    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { link } = item;
        const newLink = [];
        link.forEach(linkItem => {
          const { question } = linkItem;
          const { used, answer } = question;
          const answerKey = JSON.parse(answer).key;
          if (question) {
            if (used) {
              const {
                read_audio_url: readAudioUrl,
                read_result: readResult,
                read_score: readScore
              } = used;
              const { audioUrl, recordId } = readResult;
              const { fluency, integrity, overall } = readScore;
              newLink.push({
                ...question,
                recordId,
                // 录音地址
                recordUrl: readAudioUrl || audioUrl,
                fluency,
                integrity,
                overall,
                answerKey
              });
            } else {
              newLink.push({
                ...question,
                answerKey
              });
            }
          }
        });
        newArr.push({
          ...item,
          link: newLink
        });
      });
      return newArr;
    },

    playRecord(recordItem, index) {
      const { recordUrl: src, recordId } = recordItem;
      globalConfig.commit('playAudio', {
        src,
        index,
        type: 'record',
        recordId
      });
    },

    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
