<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box"></div>
        </div>
        <div class="scene_content">情景提示:{{ item.content }}</div>
        <div v-for="(linkItem, linkIndex) in item.link" :key="linkItem.id">
          <div class="scene_content">
            <div>Question{{ linkIndex + 1 }}:</div>
            <div>{{ linkItem.title }}</div>
          </div>
          <div class="choose_text flex_between">
            <span>
              你的回答:
              <span v-if="!linkItem.used" class="noUsed">此题未开始作答</span>
              <template v-else>
                <span
                  v-if="playRecordIndex !== linkIndex + itemIndex + 1"
                  @click="playRecord(linkItem, linkIndex + itemIndex + 1)"
                  class="anticon-yyashow anticon-bofang"
                ></span>
                <span
                  v-else
                  @click="stopRecord"
                  :style="{ color: themeColor }"
                  class="anticon-yyashow anticon-zanting"
                ></span>
              </template>
            </span>
          </div>
          <div class="other_text bg_white" v-if="linkItem.used">
            <div class="box">
              <span :style="{ color: themeColor }">{{ linkItem.overall }}</span>
              <span>得分</span>
            </div>
            <div class="box">
              <span>{{ linkItem.used.read_score.pron }}</span>
              <span>发音</span>
            </div>
            <div class="box">
              <span>{{ linkItem.used.read_score.integrity }}</span>
              <span>完整度</span>
            </div>
            <div class="box">
              <span>{{ linkItem.used.read_score.fluency }}</span>
              <span>流利度</span>
            </div>
          </div>
          <div class="other_scene_text">
            <div>关键词：</div>
            <div class="answer_key">
              <div v-for="keyItem in linkItem.keyArr" :key="keyItem">
                {{ keyItem }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLightColor } from '@/utils/common';
import globalConfig from '@/store/global_config';
export default {
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      listData: [],
      sumScore: '',
      score: '',
      title: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    },
    playRecordIndex() {
      return globalConfig.state.playRecordIndex;
    }
  },

  methods: {
    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { content, link } = item;
        const newLink = [];
        link.forEach(linkItem => {
          const { question } = linkItem;
          if (question) {
            const { used, answer } = question;
            const keyArr = JSON.parse(answer).key;
            if (used) {
              const {
                read_audio_url: readAudioUrl,
                read_result: readResult,
                read_score: readScore
              } = used;
              const { fluency, integrity, overall } = readScore;
              const { audioUrl, recordId } = readResult;
              newLink.push({
                // 录音地址
                ...question,
                recordId,
                recordUrl: readAudioUrl || audioUrl,
                fluency,
                integrity,
                overall,
                keyArr
              });
            } else {
              newLink.push({
                ...question,
                used: null,
                keyArr
              });
            }
          }
        });
        newArr.push({
          content,
          link: newLink
        });
      });
      return newArr;
    },

    playRecord(recordItem, index) {
      const { recordUrl: src, recordId } = recordItem;
      globalConfig.commit('playAudio', {
        src,
        index,
        type: 'record',
        recordId
      });
    },

    stopRecord() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
