<template>
  <vue-audio-native
  ref="audioNative"
    :showDownload="false"
    hint="加载中"
    size="large"
    :waitBuffer="false"
    :showMuted="false"
    :url="audioSrc | withAudio"
  ></vue-audio-native>
</template>

<script>
import { handleWithUrl } from '../../utils/common';
export default {
  data() {
    return {};
  },

  props: {
    itemIndex: {
      type: [String, Number],
      default: -1
    },
    audioSrc: {
      type: String,
      default: ''
    },
    isRecord: {
      type: Boolean,
      default: false
    },
    recordId: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  filters: {
    withAudio(item) {
      return handleWithUrl(item);
    }
  }
};
</script>

<style lang="less" scoped></style>
