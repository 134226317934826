<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress
                :audioSrc="item.audioUrl"
                :itemIndex="itemIndex"
              ></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.audioContent">
                <span
                  v-if="activeTextIndex == itemIndex"
                  @click="hideAudioText"
                >
                  隐藏原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div
          class="audio_content"
          :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }"
        >
          <div class="inner_text">
            {{ item.audioContent }}
          </div>
        </div>
        <div class="question_title" v-if="item.title">{{ item.title }}</div>
        <div class="question_item_content">
          <a-radio-group class="radio_group">
            <div
              class="full_width"
              v-for="(itemImg, itemImgIndex) in item.items"
              :key="itemImg.id"
            >
              <!-- 答对了 -->
              <label class="label_item " v-if="item.isRight">
                <span
                  v-if="itemImg.id == item.answer"
                  :style="{ color: themeColor }"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio
                  class="label_raido"
                  v-else
                  disabled
                  :value="itemImg.id"
                ></a-radio>
                <span class="index_margin"
                  >{{ indexPrefix[itemImgIndex] }}:</span
                >
                <span>{{ itemImg.option_text }}</span>
              </label>
              <label class="label_item" v-else>
                <span
                  v-if="item.userAnswer == itemImg.id"
                  class="anticon-cuowu anticon-yyashow"
                  mode="widthFix"
                ></span>
                <span
                  :style="{ color: themeColor }"
                  v-if="itemImg.id == item.answer"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio
                  v-if="
                    itemImg.id != item.answer && itemImg.id != item.userAnswer
                  "
                  class="label_raido"
                  disabled
                  :value="itemImg.id"
                ></a-radio>
                <span class="index_margin"
                  >{{ indexPrefix[itemImgIndex] }}:</span
                >
                <span>{{ itemImg.option_text }}</span>
              </label>
            </div>
          </a-radio-group>
          <div class="choose_text">
            我的答案：
            <template v-if="!item.used">
              <span class="noUsed">此题未开始作答</span>
            </template>
            <template v-else>
              <span v-if="item.userSelectIndex == -1" class="noUsed"
                >此题未作答</span
              >
              <span
                v-else
                :style="{ color: item.isRight ? themeColor : 'red' }"
              >
                {{ indexPrefix[item.userSelectIndex] }}
              </span>
            </template>
          </div>
          <div class="choose_text">
            参考答案：
            <span :style="{ color: themeColor }">{{
              indexPrefix[item.rightIndex]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioProgress from '@/components/audioProgress';
import { PUBLIC_DATA, getLightColor, indexPrefix } from '@/utils/common';
import { formatSingleUnitAnswer } from './index';
import globalConfig from '@/store/global_config';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 是否显示原文
      activeTextIndex: '-1',
      listData: [],
      sumScore: '',
      title: '',
      score: ''
    };
  },

  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = formatSingleUnitAnswer(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },

  methods: {
    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
