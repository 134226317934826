// 选择题的格式化

function findAnswerIndex(arr, userAnswerId) {
  if (!userAnswerId) {
    return -1;
  }
  let index = 0;
  arr.forEach((item, itemIndex) => {
    if (Number(item.id) === Number(userAnswerId)) {
      index = itemIndex;
    }
  });
  return index;
}

// 格式化一层选择题
export function formatSingleUnitAnswer(arr) {
  const newArr = [];
  arr.forEach(item => {
    const { used, content, items, answer, file_url: fileUrl } = item;
    const rightIndex = findAnswerIndex(items, answer);
    if (used) {
      const userAnswer = used.user_answer;
      const userAnswerIndex = findAnswerIndex(items, userAnswer);
      newArr.push({
        ...item,
        isRight: Number(userAnswer) === Number(answer),
        rightIndex: rightIndex,
        userSelectIndex: userAnswerIndex,
        userAnswer,
        items: items,
        audioUrl: fileUrl,
        answer: answer,
        audioContent: content
      });
    } else {
      newArr.push({
        ...item,
        rightIndex: rightIndex,
        used: null,
        items: items,
        audioUrl: fileUrl,
        audioContent: content,
        answer: answer
      });
    }
  });
  return newArr;
}

// 格式话一个音频多个问题的格式化（含有link）
export function formatLinkItemData(arr) {
  const newArr = [];
  arr.forEach(item => {
    const { link, audio_url: audioUrl, audio_content: audioContent } = item;
    const newLink = [];
    link.forEach(linkItem => {
      const { question } = linkItem;
      if (question) {
        const { answer, used, items } = question;
        const rightIndex = findAnswerIndex(items, answer);
        if (used) {
          const { user_answer: userAnswer } = used;
          const userAnswerIndex = findAnswerIndex(items, userAnswer);
          newLink.push({
            ...linkItem,
            userAnswer,
            // 是否选择正确
            isRight: Number(userAnswer) === Number(answer),
            // 选择的索引
            userSelectIndex: userAnswerIndex,
            rightIndex: rightIndex
          });
        } else {
          newLink.push({
            ...linkItem,
            used: null,
            // 选择的索引
            rightIndex: rightIndex
          });
        }
      }
    });
    newArr.push({
      ...item,
      audioUrl,
      link: newLink,
      audioContent
    });
  });
  return newArr;
}

// 格式化含有分数的(一层)
export function unitSingleRecordData(arr) {
  const newArr = [];
  arr.forEach(item => {
    const { used } = item;
    if (used) {
      const { read_audio_url: readAudioUrl, read_result: readResult, read_score: readScore } = used;
      const { fluency, overall, pron } = readScore;
      const { audioUrl, recordId } = readResult;
      newArr.push({
        ...item,
        recordId,
        fluency: Math.floor(fluency),
        overall,
        pron,
        recordUrl: readAudioUrl || audioUrl
      });
    } else {
      newArr.push({
        ...item,
        used: null
      });
    }
  });
  return newArr;
}
