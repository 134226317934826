<template>
  <div class="outer_container">
    <div class="score_box" :style="{ color: themeColor, backgroundColor: themeLightColor }">
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="tips_content">
      评分说明：
      <div
        class="single_content"
        v-for="(scoreItem, scoreIndex) in scoreArr"
        :style="{ color: scoreItem.color }"
        :key="scoreIndex"
      >
        T {{ scoreItem.en_name }}
      </div>
    </div>
    <div class="question_content">
      <div class="question_item" v-for="(item, itemIndex) in listData" :key="itemIndex">
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <!-- 播放听力原文 -->
          <div class="audio_box" v-if="item.fileUrl">
            <div class="inner_progress_box">
              <audio-progress :audioSrc="item.fileUrl" :itemIndex="itemIndex"></audio-progress>
            </div>
          </div>
        </div>
        <div class="img_box" v-if="imgUrl && item.hisImg">
          <img class="symbol_img" :src="imgUrl + item.hisImg" mode="widthFix" alt />
        </div>
        <div class="question_title text_center" v-if="item.colorWordText">
          <span>{{ item.title }}{{ ' ' }}</span>
          <span>
            (
            <span
              v-for="(wordItem, wordIndex) in item.content"
              :style="{ color: item.colorWordText[wordIndex] }"
              :key="wordItem + '_' + wordIndex"
            >
              {{ wordItem }}
            </span>
            )
          </span>
        </div>
        <div class="question_title text_center" v-else>
          {{ item.title }}{{ ' ' }}({{ item.content }})
        </div>
        <div class="choose_text align_center">
          你的回答：
          <span v-if="!item.used" class="noUsed">此题未开始作答</span>
          <div class="inner_progress_box" v-else>
            <audio-progress
              isRecord
              :audioSrc="item.recordUrl"
              :recordId="item.recordId"
              :itemIndex="itemIndex"
            ></audio-progress>
          </div>
          <div v-if="item.used">
            得分:
            <span :style="{ color: themeColor }">{{ item.overall }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, getLightColor, getWordColorText } from '@/utils/common';
import AudioProgress from '../../../../components/audioProgress';
const { imgUrl } = PUBLIC_DATA;

export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    scoreArr() {
      return globalConfig.state.scoreArr;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      listData: [],
      title: '',
      score: '',
      sumScore: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  methods: {
    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { used, file_url: fileUrl, img_url: hisImg } = item;
        if (used) {
          const {
            read_audio_url: readAudioUrl,
            read_result: readResult,
            read_score: readScore
          } = used;
          const { overall } = readScore;
          const { audioUrl, result, recordId } = readResult;
          newArr.push({
            ...item,
            recordId,
            fileUrl,
            overall,
            hisImg,
            recordUrl: readAudioUrl || audioUrl,
            // TODO 渲染单词方式更改，需要修改循环项目
            colorWordText: getWordColorText(readResult, result.rank)
          });
        } else {
          newArr.push({
            ...item,
            fileUrl
          });
        }
      });
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
