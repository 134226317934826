<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>

    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box"></div>
        </div>
        <div class="audio_content bg_white">
          <div class="inner_text">{{ item.title }}</div>
        </div>
        <div class="choose_text">
          你的回答为：
          <span v-if="!item.used" class="noUsed">此题未开始作答</span>
          <template v-else>
            <span
              v-if="playRecordIndex !== itemIndex"
              @click="playRecord(item, itemIndex)"
              class="anticon-yyashow anticon-bofang"
            ></span>
            <span
              v-else
              @click="stopAudio"
              :style="{ color: themeColor }"
              class="anticon-yyashow anticon-zanting"
            ></span>
          </template>
        </div>
        <div class="other_text bg_white" v-if="item.used">
          <div class="box">
            <span :style="{ color: themeColor }">{{ item.overall }}</span>
            <span>得分</span>
          </div>
          <div class="box">
            <span>{{ item.used.read_score.pron }}</span>
            <span>发音</span>
          </div>
          <div class="box">
            <span>{{ item.used.read_score.integrity }}</span>
            <span>完整度</span>
          </div>
          <div class="box">
            <span>{{ item.used.read_score.fluency }}</span>
            <span>流利度</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLightColor } from '@/utils/common';
import { unitSingleRecordData } from './index';
import globalConfig from '@/store/global_config';
export default {
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      listData: [],
      title: '',
      sumScore: '',
      score: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = unitSingleRecordData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    },
    playRecordIndex() {
      return globalConfig.state.playRecordIndex;
    }
  },

  methods: {
    playRecord(recordItem, index) {
      const { recordUrl: src, recordId } = recordItem;
      globalConfig.commit('playAudio', {
        src,
        index,
        type: 'record',
        recordId
      });
    },

    // 停止播放
    stopAudio() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
