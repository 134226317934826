<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="item.id"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress
                :audioSrc="item.audioUrl"
                :itemIndex="itemIndex"
              ></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.audioContent">
                <span
                  v-if="activeTextIndex == itemIndex"
                  @click="hideAudioText"
                >
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div
          class="audio_content"
          :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }"
        >
          <div class="inner_text">
            {{ item.audioContent }}
          </div>
        </div>
        <div v-for="linkItem in item.link" :key="linkItem.id">
          <div class="question_title">{{ linkItem.question.title }}</div>
          <a-radio-group class="radio_group">
            <label
              v-for="(itemImg, itemImgIndex) in linkItem.question.items"
              :key="itemImg.id"
              for="itemImg.id"
              class="label_item full_width"
            >
              <template v-if="linkItem.isRight">
                <span
                  v-if="itemImg.id == linkItem.question.answer"
                  :style="{ color: themeColor }"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio v-else disabled :value="itemImg.id"></a-radio>
                <div class="index_add">{{ indexPrefix[itemImgIndex] }}:</div>
                <span class="option_text">{{ itemImg.option_text }}</span>
              </template>
              <template v-else>
                <span
                  v-if="linkItem.userAnswer == itemImg.id"
                  class="anticon-cuowu anticon-yyashow"
                  mode="widthFix"
                ></span>
                <span
                  :style="{ color: themeColor }"
                  v-if="itemImg.id == linkItem.question.answer"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio
                  v-if="
                    itemImg.id != linkItem.question.answer &&
                      itemImg.id != linkItem.userAnswer
                  "
                  disabled
                  :value="itemImg.id"
                ></a-radio>
                <div class="index_add">{{ indexPrefix[itemImgIndex] }}:</div>
                <span class="option_text">{{ itemImg.option_text }}</span>
              </template>
            </label>
          </a-radio-group>
          <div class="choose_text">
            我的答案：
            <span v-if="!linkItem.question.used" class="noUsed"
              >此题未开始作答</span
            >
            <template v-else>
              <span v-if="linkItem.userSelectIndex == -1" class="noUsed"
                >此题未作答</span
              >
              <span
                v-else
                :style="{ color: linkItem.isRight ? themeColor : 'red' }"
              >
                {{ indexPrefix[linkItem.userSelectIndex] }}
              </span>
            </template>
          </div>
          <div class="choose_text">
            参考答案：
            <span :style="{ color: themeColor }">{{
              indexPrefix[linkItem.rightIndex]
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLightColor, indexPrefix } from '@/utils/common';
import AudioProgress from '@/components/audioProgress';
import { formatLinkItemData } from './index';
import globalConfig from '@/store/global_config';
export default {
  components: {
    AudioProgress
  },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      currentAudio: '',
      listData: [],
      activeTextIndex: -1,
      sumScore: '',
      score: '',
      title: ''
    };
  },

  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = formatLinkItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },

  methods: {
    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
