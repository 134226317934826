<template>
  <div class="outer_container">
    <div class="score_box" :style="{ color: themeColor, backgroundColor: themeLightColor }">
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div class="question_item" v-for="(item, itemIndex) in listData" :key="itemIndex">
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress :audioSrc="item.fileUrl" :itemIndex="itemIndex"></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.content">
                <span v-if="activeTextIndex == itemIndex" @click="hideAudioText">
                  隐藏原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="audio_content" :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }">
          <div class="inner_text">
            <text>{{ item.content }}</text>
          </div>
        </div>
        <div class="question_title">{{ item.title }}</div>
        <div class="choose_text  align_center">
          <span>你的回答：</span>
          <span v-if="!item.used" class="noUsed">此题未开始作答</span>
          <div class="inner_progress_box" v-else>
            <audio-progress
              isRecord
              :audioSrc="item.recordUrl"
              :itemIndex="itemIndex"
            ></audio-progress>
          </div>
          <!-- <span
              v-if="playRecordIndex !== itemIndex"
              @click="playRecord(item, itemIndex)"
              :style="{ color: themeColor }"
              class="anticon-yyashow anticon-bofang"
            ></span>
            <span
              v-else
              @click="stopRecord"
              :style="{ color: themeColor }"
              class="anticon-yyashow anticon-zanting"
            ></span> -->
          <span>
            <template v-if="item.used">
              得分:
              <span :style="{ color: themeColor }">{{ item.overall }}</span>
            </template>
          </span>
        </div>
        <div class="other_scene_text" v-if="item.used">
          <div>关键词：</div>
          <div class="answer_key">
            <div v-for="answerItem in item.answerKey" :key="answerItem">{{ answerItem }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioProgress from '@/components/audioProgress';
import { indexPrefix, getLightColor } from '@/utils/common';
import globalConfig from '@/store/global_config';
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      activeTextIndex: -1,
      listData: [],
      sumScore: '',
      score: '',
      title: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    },
    playAudioIndex() {
      return globalConfig.state.playAudioIndex;
    },
    playRecordIndex() {
      return globalConfig.state.playRecordIndex;
    }
  },

  methods: {
    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    },

    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { used, answer, file_url: fileUrl } = item;
        if (used) {
          const {
            read_audio_url: readAudioUrl,
            read_result: readResult,
            read_score: readScore
          } = used;
          const { audioUrl, recordId } = readResult;
          const { fluency, integrity, overall } = readScore;
          newArr.push({
            ...item,
            recordId,
            fileUrl,
            answerText: JSON.parse(answer).text,
            answerKey: JSON.parse(answer).key,
            // 录音地址
            recordUrl: readAudioUrl || audioUrl,
            fluency,
            integrity,
            overall
          });
        } else {
          newArr.push({
            ...item,
            fileUrl,
            used: null
          });
        }
      });
      return newArr;
    },

    // 播放录音
    playRecord(recordItem, index) {
      const { recordUrl: src, recordId } = recordItem;
      globalConfig.commit('playAudio', {
        src,
        index,
        type: 'record',
        recordId
      });
    },

    stopRecord() {
      globalConfig.commit('stopAudio');
    },

    // 播放音频
    playAudio(src, index) {
      globalConfig.commit('playAudio', { src, index });
    },

    // 停止播放
    stopAudio() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
