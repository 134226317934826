<template>
  <div class="outer_container">
    <div class="score_box" :style="{ color: themeColor, backgroundColor: themeLightColor }">
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <template v-for="(item, itemIndex) in listData">
        <div class="question_item" :key="item.id">
          <!-- 单个问题 -->
          <!-- 听力原文 -->
          <div class="audio_text">
            <div :style="{ backgroundColor: themeColor }" class="question_number">
              {{ itemIndex + 1 }}
            </div>
            <!-- 播放听力原文 -->
            <div class="audio_box">
              <template v-if="playAudioIndex === itemIndex">
                <span
                  v-if="playAudioStatus"
                  @click="stopAudio(itemIndex)"
                  :style="{ color: themeColor }"
                  class="anticon-yyashow anticon-zanting"
                ></span>
                <span
                  v-else
                  :style="{ color: themeColor }"
                  @click="playAudio(item.audioUrl, itemIndex)"
                  class="anticon-yyashow anticon-bofang"
                ></span>
              </template>
              <template v-else>
                <span
                  :style="{ color: themeColor }"
                  @click="playAudio(item.audioUrl, itemIndex)"
                  class="anticon-yyashow anticon-bofang"
                ></span>
              </template>
              <div class="text_status">
                <template v-if="item.audioContent">
                  <div v-if="audioTextStatus[itemIndex]" @click="hideAudioText(itemIndex)">
                    隐藏原文
                    <span
                      :style="{ color: themeColor }"
                      class="anticon-jiantoushang anticon-yyashow"
                    ></span>
                  </div>
                  <div v-else @click="showAudioText(itemIndex)">
                    展开原文
                    <span
                      :style="{ color: themeColor }"
                      class="anticon-jiantouxia anticon-yyashow"
                    ></span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- 听力原文 -->
          <div class="audio_content" :style="{ height: audioTextStatus[itemIndex] ? 'auto' : '0' }">
            <div class="inner_text">{{ item.audioContent }}</div>
          </div>
          <!-- 选择题 -->
          <a-radio-group class="radio_group">
            <div v-for="(itemImg, itemImgIndex) in item.items" :key="itemImg.id">
              <!-- 答对了 -->
              <label class="label_item" v-if="item.isRight">
                <span
                  v-if="itemImg.id == item.answer"
                  :style="{ color: themeColor }"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio class="label_raido" v-else disabled :value="itemImg.id"></a-radio>
                <span class="index_add">{{ indexPrefix[itemImgIndex] }}:</span>
                <img
                  mode="widthFix"
                  class="radio_img"
                  v-if="imgUrl"
                  :src="imgUrl + itemImg.option_text"
                  alt
                />
              </label>
              <!-- 答错了 -->
              <label class="label_item" v-else>
                <span
                  v-if="item.userAnswer == itemImg.id"
                  class="anticon-cuowu anticon-yyashow"
                  mode="widthFix"
                ></span>
                <span
                  :style="{ color: themeColor }"
                  v-if="itemImg.id == item.answer"
                  class="icon_img anticon-duihao anticon-yyashow"
                ></span>
                <a-radio
                  v-if="itemImg.id != item.answer && itemImg.id != item.userAnswer"
                  class="label_raido"
                  disabled
                  :value="itemImg.id"
                ></a-radio>
                <span class="index_add">{{ indexPrefix[itemImgIndex] }}:</span>
                <img
                  mode="widthFix"
                  class="radio_img"
                  v-if="imgUrl"
                  :src="imgUrl + itemImg.option_text"
                  alt
                />
              </label>
            </div>
          </a-radio-group>
          <!-- 最后的参考答案展示 -->
          <div class="choose_text">
            我的答案：
            <template v-if="!item.used">
              <span class="noUsed">此题未开始作答</span>
            </template>
            <template v-else>
              <span v-if="item.userSelectIndex == -1" class="noUsed">此题未作答</span>
              <span v-else :style="{ color: item.isRight ? themeColor : 'red' }">
                {{ indexPrefix[item.userSelectIndex] }}
              </span>
            </template>
          </div>
          <div class="choose_text">
            正确答案：
            <span :style="{ color: themeColor }">{{ indexPrefix[item.rightIndex] }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { PUBLIC_DATA, getLightColor, indexPrefix } from '@/utils/common';
import { formatSingleUnitAnswer } from './index';
import globalConfig from '@/store/global_config';
const { imgUrl } = PUBLIC_DATA;
export default {
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 音频播放状态
      audioTextStatus: [],
      currentAudio: '',
      activePlayIndex: -1,
      listData: [],
      title: '',
      sumScore: '',
      score: ''
    };
  },

  mounted() {
    this.init();
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    },
    playAudioIndex() {
      return globalConfig.state.playAudioIndex;
    },
    playAudioStatus() {
      return globalConfig.state.playAudioStatus;
    }
  },

  methods: {
    init() {
      const { questions, title, sumScore, score } = this.item;
      this.score = score;
      this.sumScore = sumScore;
      this.title = title;
      this.listData = formatSingleUnitAnswer(questions);
    },

    // 展开某项原文
    showAudioText(index) {
      const newArr = [...this.audioTextStatus];
      newArr[index] = true;
      this.audioTextStatus = [...newArr];
    },

    // 隐藏某项原文
    hideAudioText(index) {
      const newArr = [...this.audioTextStatus];
      newArr[index] = false;
      this.audioTextStatus = [...newArr];
    },

    // 播放音频
    playAudio(src, index) {
      globalConfig.commit('playAudio', { src, index });
    },

    // 停止播放
    stopAudio() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
