<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress
                :audioSrc="item.fileUrl"
                :itemIndex="itemIndex"
              ></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.content">
                <span
                  v-if="activeTextIndex == itemIndex"
                  @click="hideAudioText"
                >
                  隐藏原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div
          class="audio_content"
          :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }"
        >
          <div class="inner_text">{{ item.content }}</div>
        </div>
        <div class="choose_text align_center">
          你的回答：
          <span v-if="!item.used" class="noUsed">此题未开始作答</span>
          <div class="inner_progress_box" v-else>
            <audio-progress
              isRecord
              :audioSrc="item.recordUrl"
              :itemIndex="itemIndex"
            ></audio-progress>
          </div>
        </div>
        <div class="other_text" v-if="item.used">
          <div class="box">
            <span :style="{ color: themeColor }">{{ item.overall }}</span>
            <span>得分</span>
          </div>
          <div class="box">
            <span>{{ item.fluency }}</span>
            <span>流利度</span>
          </div>
        </div>
        <div class="other_scene_text" v-if="item.used">
          <div>参考答案：</div>
          <div class="answer_key">
            <div
              class="hasBottom"
              v-for="answerItem in item.answerText"
              :key="answerItem"
            >
              {{ answerItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PUBLIC_DATA, indexPrefix, getLightColor } from '@/utils/common';
import globalConfig from '@/store/global_config';
import AudioProgress from '@/components/audioProgress';

const { imgUrl } = PUBLIC_DATA;
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      activeTextIndex: -1,
      listData: [],
      title: '',
      score: '',
      sumScore: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    scoreArr() {
      return globalConfig.state.scoreArr;
    },
    themeLightColor() {
      return (
        globalConfig.state.themeColor &&
        getLightColor(globalConfig.state.themeColor, 0.9)
      );
    }
  },

  methods: {
    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    },

    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { used, file_url: fileUrl } = item;
        const answerText = JSON.parse(item.answer).text;
        if (used) {
          const {
            read_audio_url: readAudioUrl,
            read_result: readResult,
            read_score: readScore
          } = used;
          const { audioUrl, recordId } = readResult;
          const { fluency, overall } = readScore;
          newArr.push({
            ...item,
            fileUrl,
            recordId,
            recordUrl: readAudioUrl || audioUrl,
            fluency: Number(fluency).toFixed(2),
            overall: Number(overall).toFixed(2),
            answerText
          });
        } else {
          newArr.push({
            ...item,
            fileUrl,
            used: null,
            answerText
          });
        }
      });
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
