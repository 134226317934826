<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>

    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box">
              <audio-progress
                :audioSrc="item.audioUrl"
                :itemIndex="itemIndex"
              ></audio-progress>
            </div>
            <div class="text_status">
              <template v-if="item.audioContent">
                <span
                  v-if="activeTextIndex == itemIndex"
                  @click="hideAudioText"
                >
                  隐藏原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantoushang anticon-yyashow"
                  ></span>
                </span>
                <span v-else @click="showAudioText(itemIndex)">
                  展开原文
                  <span
                    :style="{ color: themeColor }"
                    class="anticon-jiantouxia anticon-yyashow"
                  ></span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div
          class="audio_content"
          :style="{ height: activeTextIndex == itemIndex ? 'auto' : '0' }"
        >
          <div class="inner_text">
            {{ item.audioContent }}
          </div>
        </div>
        <div class="img_box" @click="showMask(item.fileUrl)">
          <img
            class="file_url"
            mode="widthFix"
            v-if="imgUrl"
            :src="imgUrl + item.fileUrl"
            alt
          />
          <div class="right_btn">点击查看大图</div>
        </div>

        <div class="question_title">我的答案：</div>
        <template v-for="(linkItem, linkIndex) in item.link">
          <label class="label_item" :key="linkItem.id">
            <div class="choose_text">
              {{ linkIndex + 1 }}.
              <span v-if="!linkItem.used" class="noUsed">此题未开始作答</span>
              <template v-else>
                <span v-if="!linkItem.userAnswer" class="noUsed">
                  此题未作答
                </span>
                <span
                  v-else
                  :style="{ color: linkItem.isRight ? themeColor : 'red' }"
                >
                  {{ linkItem.userAnswer }}
                </span>
              </template>
            </div>
            <div class="choose_text">参考答案：{{ linkItem.answer }}</div>
          </label>
        </template>
      </div>
    </div>
    <div v-if="showMaskImg" class="mask_outer" @click="hideMask">
      <img
        class="img_url"
        mode="widthFix"
        v-if="imgUrl"
        :src="imgUrl + maskImg"
        alt
      />
    </div>
  </div>
</template>

<script>
import { PUBLIC_DATA, getLightColor, indexPrefix } from '@/utils/common';
import globalConfig from '@/store/global_config';
import AudioProgress from '@/components/audioProgress';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      // 伸缩还是展开
      showContentStatus: true,
      imgUrl: imgUrl,
      listData: [],
      showMaskImg: false,
      maskImg: '',
      activeTextIndex: '-1',
      score: '',
      sumScore: '',
      title: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },

  methods: {
    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const newLink = [];
        const {
          link,
          file_url: fileUrl,
          audio_url: audioUrl,
          audio_content: audioContent
        } = item;
        link.forEach(item => {
          const { question } = item;
          if (question) {
            const { answer, used } = question;
            if (used) {
              const userAnswer = used.user_answer;
              newLink.push({
                used,
                answer,
                userAnswer: userAnswer,
                isRight: Number(used.is_right) === 1
              });
            } else {
              newLink.push({
                used: null,
                answer
              });
            }
          }
        });
        newArr.push({
          fileUrl,
          audioUrl,
          link: newLink,
          audioContent
        });
      });
      return newArr;
    },
    showMask(imgUrl) {
      this.showMaskImg = true;
      this.maskImg = imgUrl;
    },

    hideMask() {
      this.showMaskImg = false;
      this.maskImg = '';
    },

    // 展开某项原文
    showAudioText(index) {
      this.activeTextIndex = index;
    },

    // 隐藏某项原文
    hideAudioText() {
      this.activeTextIndex = -1;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
.mask_outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .img_url {
    width: 60%;
  }
}
</style>
;
