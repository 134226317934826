<template>
  <div class="outer_container">
    <div
      class="score_box"
      :style="{ color: themeColor, backgroundColor: themeLightColor }"
    >
      <span v-if="title">{{ title }}</span>
      <span v-if="sumScore">{{ score }}/{{ sumScore }}</span>
    </div>
    <div class="tips_content">
      评分说明：
      <div
        class="single_content"
        v-for="(scoreItem, scoreIndex) in scoreArr"
        :style="{ color: scoreItem.color }"
        :key="scoreIndex"
      >
        T {{ scoreItem.en_name }}
      </div>
    </div>
    <div class="question_content">
      <div
        class="question_item"
        v-for="(item, itemIndex) in listData"
        :key="itemIndex"
      >
        <div class="audio_text">
          <div :style="{ backgroundColor: themeColor }" class="question_number">
            {{ itemIndex + 1 }}
          </div>
          <div class="audio_box">
            <div class="inner_progress_box" v-if="item.fileUrl">
              <audio-progress
                :audioSrc="item.fileUrl"
                :itemIndex="itemIndex"
              ></audio-progress>
            </div>
          </div>
        </div>
        <div class="audio_content">
          <div class="inner_text">
            <template v-if="!item.hisRrcordTextColor">
              {{ item.title }}
            </template>
            <template v-else>
              <span
                v-for="(colorItem, colorIndex) in item.hisRrcordTextColor"
                :key="colorIndex"
                :style="{ color: colorItem.itemColor || 'inherit' }"
              >
                {{ colorItem.itemText }}{{ ' ' }}
              </span>
            </template>
          </div>
        </div>

        <div class="choose_text align_center">
          你的回答：
          <span v-if="!item.used" class="noUsed">此题未开始作答</span>
          <div v-else class="inner_progress_box">
            <audio-progress
              isRecord
              :audioSrc="item.recordUrl"
              :recordId="item.recordId"
              :itemIndex="itemIndex"
            ></audio-progress>
          </div>
        </div>
        <div class="other_text bg_white" v-if="item.used">
          <div class="box">
            <span :style="{ color: themeColor }">{{ item.overall }}</span>
            <span>得分</span>
          </div>
          <div class="box">
            <span>{{ item.pron }}</span>
            <span>发音</span>
          </div>
          <div class="box">
            <span>{{ item.integrity }}</span>
            <span>完整度</span>
          </div>
          <div class="box">
            <span>{{ item.fluency }}</span>
            <span>流利度</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLightColor, getColorTextArr, PUBLIC_DATA } from '@/utils/common';
import globalConfig from '@/store/global_config';
import AudioProgress from '@/components/audioProgress';
const { imgUrl, indexPrefix } = PUBLIC_DATA;
export default {
  components: { AudioProgress },
  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 音频播放状态
      playAudioStatus: [],
      currentAudio: '',
      activePlayIndex: -1,
      listData: [],
      title: '',
      score: '',
      sumScore: ''
    };
  },
  mounted() {
    const { questions, title, sumScore, score } = this.item;
    this.score = score;
    this.sumScore = sumScore;
    this.title = title;
    this.listData = this.formatItemData(questions);
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    scoreArr() {
      return globalConfig.state.scoreArr;
    },
    themeLightColor() {
      return getLightColor(globalConfig.state.themeColor, 0.9);
    }
  },

  methods: {
    formatItemData(arr) {
      const newArr = [];
      arr.forEach(item => {
        const { title, used, file_url: fileUrl, score } = item;
        if (used) {
          const {
            read_audio_url: readAudioUrl,
            read_result: readResult,
            read_score: readScore
          } = used;
          const { fluency, integrity, overall, pron } = readScore;
          const { audioUrl, recordId } = readResult;
          const hisRrcordTextColor = getColorTextArr(readResult, score);
          newArr.push({
            ...item,
            recordId,
            title,
            fileUrl,
            recordUrl: readAudioUrl || audioUrl,
            fluency,
            integrity,
            pron,
            overall,
            hisRrcordTextColor
          });
        } else {
          newArr.push({
            ...item,
            fileUrl,
            used: null
          });
        }
      });
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
;
